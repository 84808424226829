import React, { useState, useEffect, useRef } from "react"
import { Main, ContentBox, Button, ButtonsBox, Shadow, VideoBox } from "./style"
import arrow from "../../images/arrow.png"
import { Arrow } from "../SearchPage/Header/style"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { Link } from "gatsby-plugin-react-intl"

const IndexHeader = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"

  const query = useStaticQuery(graphql`
    query Landing {
      polish: contentfulLanding(node_locale: { eq: "pl-PL" }) {
        smallTitle
        searchOffer
        reportOffer
        bigTitle
        title
      }

      english: contentfulLanding(node_locale: { eq: "en-US" }) {
        smallTitle
        searchOffer
        reportOffer
        bigTitle
        title
      }

      allContentfulVideos {
        nodes {
          video1 {
            file {
              url
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  const videoRef = useRef(null)

  // useEffect(() => {
  //   function fade(element) {
  //     let op = 0;
  //     const timer = setInterval(function() {
  //       if (op >= 1) clearInterval(timer);
  //       element.style.opacity = op;
  //       element.style.filter = 'alpha(opacity=' + op * 100 + ")";
  //       op += op * 0.1 || 0.1;
  //     }, 50);
  //   }
  //
  //   const r = document.getElementById("videlo");
  //
  //   r.oncanplaythrough = function () {
  //     setTimeout(function () {
  //       fade(document.getElementById("videlo"));
  //     })
  //   }
  // }, [videoRef]);

  return (
    <Main>
      <VideoBox>
        <video
          ref={videoRef}
          id="videlo"
          muted
          autoPlay
          playsInline
          preload="auto"
          loop
        >
          <source
            src={
              "https://" +
              query.allContentfulVideos.nodes[0].video1.file.url.substr(2)
            }
            type="video/mp4"
          />
        </video>
      </VideoBox>
      <Shadow />
      <Arrow src={arrow} alt="arrow" />
      <ContentBox>
        <h2>{data.smallTitle}</h2>
        <h3>{data.bigTitle}</h3>
        <ButtonsBox>
          <Link to={lang === "pl" ? "/wyszukiwarka/" : "/search/"}>
            <Button>{data.searchOffer}</Button>
          </Link>
          <Link to={lang === "pl" ? "/kontakt#form" : "/contact#form"}>
            <Button>{data.reportOffer}</Button>
          </Link>
        </ButtonsBox>
      </ContentBox>
    </Main>
  )
}

export default IndexHeader
