import styled from "styled-components"
import indexBackground from "../../images/index-background.jpg"

export const Main = styled.div`
  //background-size: 100% auto;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8.5rem;
  gap: 13px;
  position: relative;
  aspect-ratio: 16/9;
  background-size: cover;

  @media (max-width: 1149px) {
    width: 100%;
    height: 100vh;
    background-size: cover;
  }
`

export const ContentBox = styled.div`
  width: 83.3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  z-index: 4;
  margin-bottom: 75px;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
    gap: 10px;
  }

  h1 {
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 700;
    color: #fdfdfd;
    letter-spacing: 0.21em;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
  }

  h2 {
    font-weight: 700;
    color: #fdfdfd;
    font-size: 1.7rem;
    line-height: 3rem;
    letter-spacing: 0.21em;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  h3 {
    font-weight: 700;
    color: #fdfdfd;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 1.5rem;
    letter-spacing: 0.21em;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 799px) {
    flex-direction: column;
  }
`

export const Button = styled.button`
  color: #fdfdfd;
  border: 1px solid #fdfdfd;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border 0.2s ease-in-out;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.1rem;
  min-width: 23.5rem;
  height: 5rem;
  cursor: pointer;
  background: transparent;
  letter-spacing: 0.21em;

  &:hover {
    color: black;
    background: linear-gradient(
      90deg,
      #ce9638 0%,
      #eae69c 22.4%,
      #ce9638 48.44%,
      #b6872d 77.6%,
      #bb913c 100%
    );
    border-color: transparent;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border 0.2s ease-in-out;
  }
`

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 650px;
  height: 50vh;
  max-height: 1080px;
  background: linear-gradient(180deg, #030303 0%, rgba(196, 196, 196, 0) 100%);
  opacity: 0.55;
`

export const VideoBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 16/9;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 13px;
  background-size: cover;

  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1149px) {
    width: 100%;
    height: 100vh;
    background-size: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(1);
  }
`
