import * as React from "react"
import Layout from "../components/layout"
import IndexHeader from "../components/IndexHeader"
import SearchComponent from "../components/SearchComponent"
import Seo from "../components/seo"

const IndexPage = ({ location }) => {
  return (
    <Layout>
      <Seo
        title="Strona Główna"
        description="Będziemy Twoim partnerem w profesjonalnym obrocie nieruchomościami."
      />
      <IndexHeader location={location} />
      <SearchComponent />
    </Layout>
  )
}
export default IndexPage
